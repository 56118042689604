import React from 'react';
import Layout from '../components/layout';

const Historia = () => (
  <Layout>
    <section className="max-w-3xl mx-auto px-4 py-16">
      <h1 className="text-4xl font-extrabold text-teal-700 mb-4">
        Nuestra Historia
      </h1>
      <p className="text-base md:text-xl text-gray-800 mb-4">
        INDUBA S.R.L. es una empresa familiar vinculada al sector
        foresto-industrial, la cual se desarrolla en la República Argentina
        desde el año 1992.
      </p>
      <p className="text-base md:text-xl text-gray-800 mb-4">
        Compuesta hoy por sus cinco miembros, el matrimonio integrado por el
        Ingeniero Agrónomo-Forestal Raúl Badaracco e Inés Fernández Dos Santos,
        sus hijos Guillermo y Tulio Badaracco, quienes se encuentran abocados al
        sector industrial y, Marcelo Badaracco, a cargo del sector
        agro-forestal.
      </p>
      <p className="text-base md:text-xl text-gray-800 mb-4">
        En la actualidad, INDUBA S.R.L. emplea a 110 personas (90 en el sector
        industrial y 20 en el sector agroforestal). Posee 1100 hectáreas de
        pinos podados y, con el propósito de lograr el autoabastecimiento
        industrial, mantiene un plan de llegar a las 2000 hectáreas.
      </p>
      {/* <p className="text-base md:text-xl text-gray-800 mb-4">
        Todo ello, bajo las normas internacionales certificadas por el F.S.C.
        (Forest Stewardship Council o Consejo de Administración Forestal),
        logrando de esta manera un manejo sustentable en todos lo sectores, una
        gestión forestal ambientalmente apropiada, socialmente beneficiosa y
        económicamente viable.
      </p> */}
      <p className="text-base md:text-xl text-gray-800">
        Sumado al comercio interno, INDUBA S.R.L. esta exportando a EEUU,
        Inglaterra, Irlanda, Bélgica, Alemania, Italia, Australia y Nueva
        Zelandia. Su objetivo es consolidarse con las exportaciones de sus
        productos hacia el exterior y ampliar nuevos mercados en el futuro.
      </p>
    </section>
  </Layout>
);

export default Historia;
